<template>
  <div class="policiesOverlay fade-in" v-show="showPoliciesOverlay">
    <div class="policiesOverlayContainer" ref="scrollContainer">
      <div class="policiesContent" v-if="loadingState === 0">
        <jwl-language-switcher />
        <div class="text" v-html="policyText"></div>
        <div class="policiesOverlayRow">
          <div class="mediaPolicy" v-if="mediaPolicy">
            <div v-html="mediaPolicyText"></div>
            <form-element :hide-lable="true" field-name="media" field-type="boolean" form-style="multi-row" :value="mediaPolicyAccepted" @change="mediaPolicyAccepted = $event"></form-element>
          </div>
        </div>
        <div class="policiesOverlayRow">
          <div class="announcementPolicy" v-if="announcementPolicy">
            <div v-html="announcementPolicyText"></div>
            <form-element :hide-lable="true" field-name="announcement" field-type="boolean" form-style="multi-row" :value="announcementPolicyAccepted" @change="announcementPolicyAccepted = $event"></form-element>
          </div>
        </div>
        <div class="policiesOverlayRow">
          <jwl-button name="policyAccepted" @button-clicked="acceptPolicies">Accept</jwl-button>
          <jwl-button class="jwl-button red" name="policyDismiss" @button-clicked="logout">Dismiss</jwl-button>
        </div>
      </div>
      <div class="policiesLoading" v-if="loadingState === 1">
        <jwl-icon loading-icon margin-right /> {{$t('general.loadingData')}}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import JwlIcon from '@base/components/JwlIcon.vue';
import { mapState, mapStores } from 'pinia';
import User from '@base/store/User';
import JwlLanguageSwitcher from '@base/components/JwlLanguageSwitcher.vue';
import useModuleStore from '@base/store/moduleStore';
import { Watch } from 'vue-property-decorator';
import FormElement from '@base/components/FormElement.vue';
import useLearningStore from '@/store/learningStore';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlLanguageSwitcher,
    JwlButton,
    JwlIcon,
    FormElement,
  },
  computed: {
    ...mapStores(useModuleStore, useLearningStore),
    ...mapState(useModuleStore, [
      'currentUser',
    ]),
  },
})
export default class JwlPoliciesOverlay extends Vue {
  moduleStore!: any;
  learningStore!: any;
  currentUser!: User;
  policyId!: '';
  loadingState = 0;
  showPoliciesOverlay = true;
  policyText = '';
  announcementPolicyText = '';
  announcementPolicy = false;
  announcementPolicyAccepted = true;
  mediaPolicyText = '';
  mediaPolicy = false;
  mediaPolicyAccepted = true;

  mounted () {
    this.getPolicy();
  }

  getPolicy (): void {
    this.loadingState = 1;
    this.learningStore.getData('policy-api/current-policy').then((data) => {
      this.policyText = data.policyText;
      this.policyId = data.policyId;
      this.announcementPolicyText = data.announcementPolicyText;
      this.announcementPolicy = data.announcementPolicy;
      this.mediaPolicyText = data.mediaPolicyText;
      this.mediaPolicy = data.mediaPolicy;
      this.$nextTick(() => {
        this.loadingState = 0;
      });
    }).catch(() => {
      // console.log(e);
    });
  }

  acceptPolicies (): void {
    this.loadingState = 1;
    const formData = new FormData();
    formData.set('id', this.policyId);
    if (this.announcementPolicy) {
      formData.set('announcementPolicyAccepted', JSON.stringify(this.announcementPolicyAccepted));
      formData.set('mediaPolicyAccepted', JSON.stringify(this.mediaPolicyAccepted));
    }
    this.learningStore.postData({
      url: 'profile/update-user-policy-acception',
      formData,
    }).then((data) => {
      if (data.success === true) {
        this.currentUser.policyAccepted = data.policyAccepted;
        this.$nextTick(() => {
          this.loadingState = 2;
        });
      } else {
        this.loadingState = 0;
      }
    }).catch(() => {
      // console.log(e);
    });
  }

  @Watch('$route')
  changeLocale (): void {
    this.getPolicy();
  }

  logout (): void {
    this.moduleStore.logOutUser();
    this.$router.push({ name: 'login' });
  }
}
</script>

<style lang="scss">
@use "@base/styles/variables";
@use "@base/styles/mixins";

.policiesOverlay{
  align-items: flex-start;
  backdrop-filter: blur(4px);
  background-color: transparentize(variables.$black, .2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity .25s;
  width: 100%;
  z-index: 1010;
  &.fade-in{
    opacity: 1;
  }
  .policiesOverlayContainer{
    background-color: variables.$white;
    border-radius: variables.$border-radius;
    box-sizing: border-box;
    margin: 10vh auto;
    max-width: variables.$container-width * 0.6;
    padding: 1.5rem 2rem;
    width: 90%;
    .policiesOverlayRow{
      margin-top: 1.5rem;
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      grid-gap: 0.5rem;
      .jwl-button.red{
        --button-color: var(--color-error);
      }
    }
  }
  h2 {
    @apply text-2xl my-0;
  }
}

</style>
